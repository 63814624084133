import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const searchSchema = paginationSchema.extend({
	filter: z.object({ sku: z.string().optional(), name: z.string().optional() }).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const materialsQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/v1/materials", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[sku]": params.filter?.sku,
				"filter[name]": params.filter?.name,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/materials/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) => context.queryClient.ensureQueryData(materialsQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(materialsQueryOptions(searchParams));
	const materials = query.data;

	useCustomDocumentTitle(`${t("materials")} (${materials.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof materials)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("name", {
				header: t("name"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("sku", {
				header: t("sku"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("thickness", {
				header: t("thickness"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("grain", {
				header: t("grain"),
				cell: (info) => <TableCell variant="default" value={info.getValue().label} />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "materials",
		pagination: { id: Route.id, rowCount: materials.meta.total },
		rowSelection: false,
		columnVisibility: { id: false },
	});

	const table = useReactTable({
		data: materials.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{ table, rowLink: { to: "/materials/$id" } }}
			topbarProps={{
				title: t("materials"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: t("sku"),
					value: "sku",
				},
				{
					variant: "input",
					label: t("name"),
					value: "name",
				},
			]}
		/>
	);
}
