import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = [
	"created_at",
	"-created_at",
	"updated_at",
	"-updated_at",
	"production_date",
	"-production_date",
] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			name: z.string().optional(),
			production_date: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const productionBatchesQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/v1/production-batches", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[name]": params.filter?.name,
				"filter[production_date]": params.filter?.production_date,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/production-batches/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) =>
		context.queryClient.ensureQueryData(productionBatchesQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(productionBatchesQueryOptions(searchParams));
	const productionBatches = query.data;

	useCustomDocumentTitle(`${t("production_batches")} (${productionBatches.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof productionBatches)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("increment_id", {
				header: t("increment_id"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("comment", {
				header: t("comment"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "production-batches",
		pagination: { id: Route.id, rowCount: productionBatches.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: true, updated_at: false },
	});

	const table = useReactTable({
		data: productionBatches.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{
				table,
				rowLink: { to: "/production-batches/$id" },
				sort: ["created_at", "updated_at", "production_date"],
			}}
			topbarProps={{
				title: t("production_batches"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: t("name"),
					value: "name",
				},
				{
					variant: "date",
					label: t("production_date"),
					value: "production_date",
				},
			]}
		/>
	);
}
