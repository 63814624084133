import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { $api } from "~/api";

export const userQueryOptions = $api.queryOptions("get", "/v1/me");

export const useUser = () => useSuspenseQuery(userQueryOptions);

export const useLogout = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return $api.useMutation("post", "/v1/logout", {
		onSettled: async () => {
			await queryClient.invalidateQueries();
			await navigate({ to: "/login" });
		},
	});
};
