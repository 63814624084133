import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import { Typography } from "~/components/utilities/Typography";
import { useToast } from "~/components/utilities/toast/use-toast";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { PageLayout } from "~/layouts/PageLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";
import { formatter } from "~/library/utilities";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "~/types/route";

const restSheetQueryOptions = (id: string) => {
	return $api.queryOptions("get", "/v1/rest-sheets/{restSheet}", {
		params: { path: { restSheet: id } },
	});
};

export const Route = createFileRoute("/_authenticated/rest-sheets/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(restSheetQueryOptions(params.id)),
});

function RouteComponent() {
	const navigate = useNavigate();
	const { toast } = useToast();
	const { t, i18n } = useTranslation();
	const params = Route.useParams();
	const restSheetQuery = useSuspenseQuery(restSheetQueryOptions(params.id));
	const restSheet = restSheetQuery.data;

	useCustomDocumentTitle(`${t("rest_sheet")} #${restSheetQuery.data.barcode}`);

	const deleteRestSheet = $api.useMutation("delete", "/v1/rest-sheets/{restSheet}", {
		onSuccess: async () => {
			toast({ title: t("rest_sheet_deleted") });
			await navigate({
				to: "/rest-sheets",
				search: { page: DEFAULT_PAGE_INDEX, per_page: DEFAULT_PAGE_SIZE },
			});
		},
	});

	return (
		<PageLayout
			title={`${t("rest_sheet")} #${restSheetQuery.data.barcode}`}
			backLink={{ to: "/rest-sheets", children: t("rest_sheets") }}
			actions={[
				{
					id: "delete",
					icon: faTrash,
					tooltip: t("delete_rest_sheet"),
					onClick: () =>
						deleteRestSheet.mutateAsync({
							params: { path: { restSheet: restSheetQuery.data.id } },
						}),
					withAffirmation: {
						title: t("are_you_sure_you_want_to_delete_this_rest_sheet"),
						description: t("this_action_cannot_be_undone"),
						cancel: t("no_dont_delete"),
						confirm: t("yes_delete"),
					},
				},
			]}
			sidebar={{
				variant: "fixed",
				children: (
					<>
						<SideBarBlock title={t("information")}>
							<SidebarItem
								label={t("length")}
								value={formatter({
									lang: i18n.language,
									value: restSheet.length,
									variant: "millimeter",
								})}
							/>
							<SidebarItem
								label={t("width")}
								value={formatter({
									lang: i18n.language,
									value: restSheet.width,
									variant: "millimeter",
								})}
							/>
							<SidebarItem label={t("barcode")} value={restSheet.barcode} />
							<SidebarItem label={t("rack_code")} value={restSheet.rack_code} />
						</SideBarBlock>

						<SideBarBlock title={t("additional_information")}>
							<SidebarDateItem variant="reserved_at" value={restSheet.reserved_at} />
							<SidebarDateItem variant="used_at" value={restSheet.used_at} />
							<SidebarDateItem variant="created_at" value={restSheet.created_at} />
							<SidebarDateItem variant="updated_at" value={restSheet.updated_at} />
						</SideBarBlock>
					</>
				),
			}}
		>
			<div className="flex flex-col items-start gap-4">
				<div className="app-background-floating-box w-full p-4">
					<Typography weight="bold" className="mb-0.5">
						{t("material")}
					</Typography>

					{/* @niels: todo */}
					{/*{Object.entries(restSheet.material).map(([key, value]) => (*/}
					{/*	<Typography iconColor="gray" iconSize="sm" key={key}>*/}
					{/*		<span className="text-gray-11 text-sm">{key}:</span>{" "}*/}
					{/*		{typeof value === "object" ? value.label : value}*/}
					{/*	</Typography>*/}
					{/*))}*/}
				</div>
			</div>
		</PageLayout>
	);
}
