import type { QueryClient } from "@tanstack/react-query";
import { Outlet, createRootRouteWithContext, useRouter } from "@tanstack/react-router";
import nProgress from "nprogress";
import { Fragment, type PropsWithChildren, lazy, useEffect, useRef, useState } from "react";
import { ErrorPageComponent } from "~/components/route/Error";
import { NotFoundPageComponent } from "~/components/route/NotFound";
import { PendingPageComponent } from "~/components/route/Pending";
import { userQueryOptions } from "~/hooks/use-user";

interface Context {
	queryClient: QueryClient;
}

const TanStackRouterDevtools = lazy(async () => {
	const devTools = await import("@tanstack/router-devtools");

	return { default: devTools.TanStackRouterDevtools };
});

const ReactQueryDevtools = lazy(async () => {
	const devTools = await import("@tanstack/react-query-devtools");

	return { default: devTools.ReactQueryDevtools };
});

export const Route = createRootRouteWithContext<Context>()({
	component: () => (
		<DevToolsWrapper>
			<NProgress />
			<Outlet />
		</DevToolsWrapper>
	),
	loader: async ({ context }) => {
		const user = await context.queryClient.ensureQueryData(userQueryOptions).catch(() => null);

		return { user };
	},
	notFoundComponent: (props) => {
		const loaderData = Route.useLoaderData();

		if (loaderData.user) {
			return <NotFoundPageComponent withLayout {...props} />;
		}

		return <NotFoundPageComponent fullHeight {...props} />;
	},
	errorComponent: ErrorPageComponent,
	pendingComponent: PendingPageComponent,
});

function NProgress() {
	const router = useRouter();
	const delay = 150;
	const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

	useEffect(() => {
		const unsubscribe = router.subscribe("onBeforeLoad", ({ pathChanged }) => {
			if (pathChanged) {
				// clear the timeout if the path changed before the delay
				clearTimeout(timeoutRef.current);

				// if the path changed, start the progress bar after a delay to prevent it from flashing
				timeoutRef.current = setTimeout(() => nProgress.start(), delay);
			}
		});

		return () => {
			clearTimeout(timeoutRef.current);
			unsubscribe();
		};
	}, [router.subscribe]);

	useEffect(() => {
		return router.subscribe("onLoad", () => {
			// clear the timeout if the page loaded before the delay
			clearTimeout(timeoutRef.current);

			nProgress.done();
		});
	}, [router.subscribe]);

	return null;
}

function DevToolsWrapper(props: PropsWithChildren) {
	const [showDevtools, setShowDevtools] = useState(false);

	useEffect(() => {
		function onKeyPress(event: KeyboardEvent) {
			if (event.key === "d" && event.ctrlKey) {
				setShowDevtools((old) => !old);
			}
		}

		window.addEventListener("keydown", onKeyPress);

		return () => {
			window.removeEventListener("keydown", onKeyPress);
		};
	}, []);

	if (showDevtools) {
		return (
			<Fragment>
				{props.children}

				<TanStackRouterDevtools initialIsOpen />
				<ReactQueryDevtools initialIsOpen />
			</Fragment>
		);
	}

	return props.children;
}
