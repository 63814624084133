import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = [
	"length",
	"-length",
	"width",
	"-width",
	"reserved_at",
	"-reserved_at",
	"used_at",
	"-used_at",
	"created_at",
	"-created_at",
	"updated_at",
	"-updated_at",
] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			barcode: z.string().optional(),
			rack_code: z.string().optional(),
			length: z.string().optional(),
			width: z.string().optional(),
			reserved_at: z.string().optional(),
			used_at: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const restSheetsQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/v1/rest-sheets", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[barcode]": params.filter?.barcode,
				"filter[rack_code]": params.filter?.rack_code,
				"filter[length]": params.filter?.length,
				"filter[width]": params.filter?.width,
				"filter[reserved_at]": params.filter?.reserved_at,
				"filter[used_at]": params.filter?.used_at,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/rest-sheets/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) => context.queryClient.ensureQueryData(restSheetsQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(restSheetsQueryOptions(searchParams));
	const restSheets = query.data;

	useCustomDocumentTitle(`${t("rest_sheets")} (${restSheets.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof restSheets)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("barcode", {
				header: t("barcode"),
				cell: (info) => (
					<TableCell
						variant="link"
						value={info.getValue()}
						props={{ to: "/rest-sheets/$id", params: { id: info.row.original.id } }}
					/>
				),
			}),
			columnHelper.accessor("rack_code", {
				header: t("rack_code"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("reserved_at", {
				header: t("reserved_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="reserved_at" />,
			}),
			columnHelper.accessor("used_at", {
				header: t("used_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="used_at" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "rest-sheets",
		pagination: { id: Route.id, rowCount: restSheets.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: restSheets.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			tableProps={{
				table,
				sort: ["length", "width", "reserved_at", "used_at", "created_at", "updated_at"],
				rowLink: { to: "/rest-sheets/$id" },
			}}
			topbarProps={{
				title: t("rest_sheets"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
			filterItems={[
				{
					variant: "input",
					label: t("barcode"),
					value: "barcode",
				},
				{
					variant: "input",
					label: t("rack_code"),
					value: "rack_code",
				},
				{
					variant: "input",
					label: t("length"),
					value: "length",
				},
				{
					variant: "input",
					label: t("width"),
					value: "width",
				},
				{
					variant: "date",
					label: t("reserved_at"),
					value: "reserved_at",
				},
				{
					variant: "date",
					label: t("used_at"),
					value: "used_at",
				},
			]}
		/>
	);
}
