import { faPen, faTrash, faTruckClock } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { match, P } from "ts-pattern";
import { z } from "zod";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import type { components, paths } from "~/api/openapi";
import { FastActions } from "~/components/FastActions";
import { StatusCircleByVariant } from "~/components/StatusCircle";
import { AlertDialog } from "~/components/dialog/AlertDialog";
import { Dialog } from "~/components/dialog/Default";
import { Form, SubmitButton } from "~/components/form/Form";
import { FormInput } from "~/components/form/Input";
import { FormSelect } from "~/components/form/Select";
import { inputVariant } from "~/components/inputs";
import { TextArea } from "~/components/inputs/TextArea";
import { Checkbox } from "~/components/utilities/Checkbox";
import { Icon } from "~/components/utilities/IconVariant";
import { Anchor } from "~/components/utilities/interactive/Anchor";
import { TableCell } from "~/components/utilities/table/Cell";
import { useDialog } from "~/hooks/use-dialog";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useZodForm } from "~/hooks/use-form";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";
import { formatter } from "~/library/utilities";
import type { OpenProps } from "~/types/Utilities";

const purchaseOrderQueryOptions = (id: string) => {
	return $api.queryOptions("get", "/v1/purchase-orders/{purchaseOrder}", {
		params: { path: { purchaseOrder: id } },
	});
};

type SuccessResponse =
	paths["/v1/purchase-orders/{purchaseOrder}"]["get"]["responses"][200]["content"]["application/json"];

type PurchaseOrderItemType = NonNullable<SuccessResponse["purchase_order_items"]>[number];
type PurchaseOrderShipment = NonNullable<SuccessResponse["purchase_order_shipments"]>[number];

export const Route = createFileRoute("/_authenticated/purchase-orders/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(purchaseOrderQueryOptions(params.id)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const params = Route.useParams();
	const queryClient = useQueryClient();
	const query = useSuspenseQuery(purchaseOrderQueryOptions(params.id));
	const purchaseOrder = query.data;

	useCustomDocumentTitle(`${t("purchase_order")} #${purchaseOrder.increment_id}`);

	const [openCreateShipmentDialog, setOpenCreateShipmentDialog] = useState(false);

	const updateOptimization = $api.useMutation("put", "/v1/optimizations/{optimization}", {
		onSuccess: async () => {
			await queryClient.invalidateQueries();
		},
	});

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<components["schemas"]["PurchaseOrderItemResource"]>();

		return [
			columnHelper.display({
				id: "checkbox",
				enableHiding: false,
				header: ({ table }) => (
					<Checkbox
						checked={table.getIsAllRowsSelected()}
						indeterminate={table.getIsSomeRowsSelected()}
						onChange={table.getToggleAllRowsSelectedHandler()}
						title={t("select_all")}
					/>
				),
				cell: ({ row }) => {
					if (!row.getCanSelect()) return null;

					return (
						<Checkbox
							checked={row.getIsSelected()}
							disabled={!row.getCanSelect()}
							onChange={row.getToggleSelectedHandler()}
							title={t("select_row")}
						/>
					);
				},
			}),
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("order_item.order", {
				header: `${t("order")} #`,
				cell: (info) => {
					const order = info.getValue();

					if (!order) return null;

					return (
						<TableCell
							variant="link"
							props={{ to: "/orders/$id", params: { id: order.id } }}
							value={order.increment_id}
						/>
					);
				},
			}),
			columnHelper.accessor("purchase_order_shipment", {
				header: t("supplier_reference"),
				cell: (info) => (
					<TableCell variant="default" value={info.getValue()?.supplier_reference ?? "-"} />
				),
			}),
			columnHelper.accessor("purchasable.producible", {
				header: t("supplier_sku"),
				cell: (info) => {
					const value = match(info.getValue())
						.with({ supplier_name: P.string }, (p) => p.supplier_name)
						.otherwise(() => null);

					return <TableCell variant="default" value={value} />;
				},
			}),
			columnHelper.accessor("purchasable.producible.supplier_name", {
				header: t("supplier_product_name"),
				cell: (info) => {
					const value = match(info.getValue())
						.with({ supplier_sku: P.string }, (p) => p.supplier_sku)
						.otherwise(() => null);

					return (
						<TableCell variant="tooltip" props={info.row.original.order_item?.name} value={value} />
					);
				},
			}),
			columnHelper.accessor("quantity", {
				header: t("quantity"),
				cell: (info) => <TableCell variant="default" value={info.getValue().toString()} />,
			}),
			columnHelper.accessor("purchasable.length", {
				header: t("length"),
				cell: (info) => {
					const purchasable = info.row.original.purchasable;

					return (
						<NumberInput
							value={info.getValue()}
							onUpdate={(value) => {
								if (!purchasable) return;

								updateOptimization.mutate({
									params: { path: { optimization: purchasable.id } },
									body: {
										length: value,
										width: purchasable.width,
										quantity: info.row.original.quantity,
										trim_length: purchasable.trim_length,
										trim_width: purchasable.trim_width,
									},
								});
							}}
						/>
					);
				},
			}),
			columnHelper.accessor("purchasable.width", {
				header: t("width"),
				cell: (info) => {
					const purchasable = info.row.original.purchasable;

					return (
						<NumberInput
							value={info.getValue()}
							onUpdate={(value) => {
								if (!purchasable) return;

								updateOptimization.mutate({
									params: { path: { optimization: purchasable.id } },
									body: {
										length: purchasable.length,
										width: value,
										quantity: info.row.original.quantity,
										trim_length: purchasable.trim_length,
										trim_width: purchasable.trim_width,
									},
								});
							}}
						/>
					);
				},
			}),
			columnHelper.accessor("order_item.order.production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t, updateOptimization.mutate]);

	const data = useMemo(
		() => purchaseOrder.purchase_order_items?.sort((a) => (a.purchase_order_shipment?.id ? 1 : -1)),
		[purchaseOrder.purchase_order_items],
	);

	const tableProps = useTableProps({
		id: "purchase-order",
		pagination: false,
		rowSelection: true,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const selected = useMemo(
		() =>
			tableProps.selected.filter((id) =>
				purchaseOrder.purchase_order_items?.some((item) => item.id === id),
			),
		[tableProps.selected, purchaseOrder.purchase_order_items],
	);

	const table = useReactTable({
		data: data ?? [],
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
		enableRowSelection: (row) => !row.original.purchase_order_shipment?.id,
	});

	const customRows = useMemo(() => {
		const hasNonShippedItems = data?.some((item) => !item.purchase_order_shipment);
		const firstShipmentIndex = data?.findIndex((item) => item.purchase_order_shipment);

		const rows = [];
		if (hasNonShippedItems) {
			rows.push({
				index: 0,
				title: <StatusCircleByVariant variant="purchase-order" current="purchased" />,
			});
		}

		if (firstShipmentIndex !== undefined && firstShipmentIndex !== -1) {
			rows.push({
				index: firstShipmentIndex,
				title: <StatusCircleByVariant variant="purchase-order" current="completed" />,
			});
		}

		return rows;
	}, [data]);

	const updatePurchaseOrder = $api.useMutation("put", "/v1/purchase-orders/{purchaseOrder}", {
		onSuccess: () => {
			return queryClient.invalidateQueries();
		},
	});

	return (
		<>
			<TableLayout
				tableProps={{
					table,
					customRows,
					rowLink: { origin: "order_item.order.id", to: "/orders/$id" },
				}}
				topbarProps={{
					title: `${t("purchase_order")} #${purchaseOrder.increment_id}`,
					modules: { pagination: false, rowSelection: true, columnVisibility: true },
					backLink: { to: "/purchase-orders", children: t("purchase_orders") },
				}}
				sidebar={{
					variant: "fixed",
					children: (
						<>
							<StatusCircleByVariant
								variant="purchase-order"
								current={purchaseOrder.status.value}
							/>

							<SideBarBlock title={t("supplier")}>
								<SidebarItem value={purchaseOrder.supplier.label} />
							</SideBarBlock>

							<SideBarBlock title={t("deliveries")}>
								{purchaseOrder.purchase_order_shipments?.map((shipment) => (
									<Shipment key={shipment.id} shipment={shipment} />
								))}
							</SideBarBlock>

							<SideBarBlock title={`${t("description")} / ${t("note")}`}>
								<TextArea
									className="w-full"
									value={purchaseOrder.description ?? ""}
									onBlur={(e) => {
										if (e.target.value !== purchaseOrder.description) {
											updatePurchaseOrder.mutate({
												params: { path: { purchaseOrder: params.id } },
												body: {
													// @ts-ignore - idk how to fix this yet
													status: purchaseOrder.status.value,
													description: e.target.value,
												},
											});
										}
									}}
								/>
							</SideBarBlock>

							<SideBarBlock title={t("additional_information")}>
								<SidebarDateItem variant="created_at" value={purchaseOrder.created_at} />
								<SidebarDateItem variant="updated_at" value={purchaseOrder.updated_at} />
							</SideBarBlock>
						</>
					),
				}}
			/>

			<FastActions
				selected={selected}
				clearSelected={() => table.toggleAllRowsSelected(false)}
				actions={[
					{
						id: "create-shipment",
						label: t("create_delivery"),
						icon: faTruckClock,
						action: () => setOpenCreateShipmentDialog(true),
					},
				]}
			/>

			<ShipmentDialog
				open={openCreateShipmentDialog}
				setOpen={setOpenCreateShipmentDialog}
				selected={selected}
				clearSelected={() => table.toggleAllRowsSelected(false)}
			/>
		</>
	);
}

function Shipment({ shipment }: { shipment: PurchaseOrderShipment }) {
	const { t, i18n } = useTranslation();
	const updateDialog = useDialog();
	const deleteDialog = useDialog();

	return (
		<div className="relative flex w-full flex-col gap-1 rounded-lg border border-gray-3 bg-background p-2">
			<UpdateShipmentDialog shipment={shipment} {...updateDialog.props} />

			<DeleteShipmentDialog shipment={shipment} {...deleteDialog.props} />

			<div className="absolute top-2 right-2 flex gap-2">
				<button
					type="button"
					onClick={updateDialog.toggle}
					className=" aspect-square w-8 rounded-lg bg-gray-2 enter:bg-gray-3 text-primary-11 text-sm safe-motion:transition-colors focus:outline-none"
					title={t("update_delivery")}
				>
					<Icon icon={faPen} />
				</button>

				<button
					type="button"
					onClick={deleteDialog.toggle}
					className="aspect-square w-8 rounded-lg bg-gray-2 enter:bg-gray-3 text-primary-11 text-sm safe-motion:transition-colors focus:outline-none"
					title={t("update_delivery")}
				>
					<Icon icon={faTrash} />
				</button>
			</div>

			<StatusCircleByVariant variant="shipment" current={shipment.status.value} />

			<SidebarItem label={t("supplier_reference")} value={shipment.supplier_reference} />

			<SidebarItem
				label={t("supplier_delivery_date")}
				value={formatter({
					lang: i18n.language,
					variant: "date",
					value: new Date(shipment.supplier_delivery_date),
				})}
			/>

			{shipment.file_path && (
				<Anchor download href={shipment.file_path}>
					{t("download_delivery_note")}
				</Anchor>
			)}
		</div>
	);
}

interface ShipmentDialogProps extends OpenProps {
	selected: string[];
	clearSelected: () => void;
}

function ShipmentDialog({ open, setOpen, selected, clearSelected }: ShipmentDialogProps) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const params = Route.useParams();

	const formSchema = z.object({
		supplier_reference: z.string(),
		supplier_delivery_date: z.string(),
		status: z.literal("created").or(z.literal("delivered")).optional(),
		file: z.custom<FileList>(),
	});

	const form = useZodForm({
		schema: formSchema,
		defaultValues: {
			supplier_delivery_date: new Date().toLocaleDateString("en-CA"),
			status: "created",
		},
	});

	const createShipmentMutation = $api.useMutation("post", "/v1/purchase-order-shipments", {
		onSuccess: async () => {
			await queryClient.invalidateQueries();

			setOpen(false);

			clearSelected();
		},
	});

	return (
		<Dialog
			open={open}
			setOpen={setOpen}
			title={t("create_delivery")}
			description={t("create_a_new_delivery_for_the_selected_items")}
		>
			<Form
				form={form}
				onSubmit={(data) => {
					return createShipmentMutation.mutateAsync({
						body: {
							purchase_order_id: params.id,
							supplier_reference: data.supplier_reference,
							supplier_delivery_date: data.supplier_delivery_date,
							status: data.status,
							purchase_order_item_ids: selected,
						},
						bodySerializer: (body) => {
							const fd = new FormData();

							fd.append("purchase_order_id", params.id);
							fd.append("supplier_reference", body.supplier_reference);
							fd.append("supplier_delivery_date", body.supplier_delivery_date);
							if (body.status) fd.append("status", body.status);

							for (const [i, id] of body.purchase_order_item_ids.entries()) {
								fd.append(`purchase_order_item_ids[${i}]`, id);
							}

							if (data.file?.[0]) {
								fd.append("file", data.file[0]);
							}

							return fd;
						},
					});
				}}
				fieldSetProps={{ className: "flex flex-col gap-4" }}
			>
				<FormInput label={t("supplier_reference")} {...form.register("supplier_reference")} />
				<FormInput
					label={t("supplier_delivery_date")}
					type="date"
					{...form.register("supplier_delivery_date")}
				/>
				<FormSelect label={t("supplier_delivery_status")} {...form.register("status")}>
					<option value="created">{t("created")}</option>
					<option value="delivered">{t("delivered")}</option>
				</FormSelect>
				<FormInput
					label={t("delivery_note")}
					type="file"
					accept="application/pdf"
					{...form.register("file")}
				>
					{t("upload_delivery_note")}
				</FormInput>

				<SubmitButton>{t("create_delivery")}</SubmitButton>
			</Form>
		</Dialog>
	);
}

interface DeleteShipmentDialogProps extends OpenProps {
	shipment: PurchaseOrderShipment;
}

function DeleteShipmentDialog({ shipment, open, setOpen }: DeleteShipmentDialogProps) {
	const { t } = useTranslation();
	const ref = shipment.supplier_reference ?? shipment.id;
	const queryClient = useQueryClient();

	const mutation = $api.useMutation(
		"delete",
		"/v1/purchase-order-shipments/{purchase_order_shipment}",
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries();

				setOpen(false);
			},
		},
	);

	return (
		<AlertDialog
			title={t("delete_delivery", { delivery: ref })}
			description={t("delete_delivery_description", { delivery: ref })}
			open={open}
			setOpen={setOpen}
			action={t("delete")}
			actionFn={async () => {
				return mutation.mutateAsync({
					params: { path: { purchase_order_shipment: shipment.id } },
				});
			}}
		/>
	);
}

interface UpdateShipmentDialogProps extends OpenProps {
	shipment: PurchaseOrderShipment;
}

function UpdateShipmentDialog({ open, setOpen, shipment }: UpdateShipmentDialogProps) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [editFile, setEditFile] = useState(false);

	const formSchema = z.object({
		supplier_reference: z.string(),
		supplier_delivery_date: z.string(),
		status: z.literal("created").or(z.literal("delivered")).optional(),
		file: z.custom<FileList>().optional(),
	});

	const ref = shipment.supplier_reference ?? shipment.id;

	const form = useZodForm({
		schema: formSchema,
		defaultValues: {
			supplier_reference: shipment.supplier_reference,
			supplier_delivery_date: new Date(shipment.supplier_delivery_date).toLocaleDateString("en-CA"),
			// @ts-ignore - idk how to fix this yet
			status: shipment.status.value,
		},
	});

	const updateShipmentMutation = $api.useMutation(
		"put",
		"/v1/purchase-order-shipments/{purchase_order_shipment}",
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries();

				setOpen(false);
			},
		},
	);

	return (
		<Dialog open={open} setOpen={setOpen} title={t("update_delivery", { delivery: ref })}>
			<Form
				form={form}
				onSubmit={(data) =>
					updateShipmentMutation.mutateAsync({
						params: { path: { purchase_order_shipment: shipment.id } },
						body: {
							supplier_reference: data.supplier_reference,
							supplier_delivery_date: data.supplier_delivery_date,

							status: data.status,
						},
						bodySerializer: (body) => {
							const fd = new FormData();

							fd.append("supplier_reference", body.supplier_reference);
							fd.append("supplier_delivery_date", body.supplier_delivery_date);
							if (body.status) fd.append("status", body.status);

							if (data.file?.[0]) {
								fd.append("file", data.file[0]);
							}

							return fd;
						},
					})
				}
				fieldSetProps={{ className: "flex flex-col gap-4" }}
			>
				<FormInput label={t("supplier_reference")} {...form.register("supplier_reference")} />

				<FormInput
					label={t("supplier_delivery_date")}
					type="date"
					{...form.register("supplier_delivery_date")}
				/>

				<FormSelect
					defaultValue={shipment.status.value}
					label={t("supplier_delivery_status")}
					{...form.register("status")}
				>
					<option value="created">{t("created")}</option>
					<option value="delivered">{t("delivered")}</option>
				</FormSelect>

				<div className="flex flex-col gap-2">
					<Checkbox
						checked={editFile}
						onChange={() => setEditFile((prev) => !prev)}
						title={t("edit_file")}
						showTitle
					/>

					{editFile ? (
						<FormInput type="file" accept="application/pdf" {...form.register("file")}>
							{t("upload_delivery_note")}
						</FormInput>
					) : (
						<Anchor download href={shipment.file_path ?? undefined}>
							{t("download_delivery_note")}
						</Anchor>
					)}
				</div>

				<SubmitButton>{t("update_delivery")}</SubmitButton>
			</Form>
		</Dialog>
	);
}

interface NumberInputProps {
	value: number;
	onUpdate: (value: number) => void;
}

function NumberInput({ value, onUpdate }: NumberInputProps) {
	const [state, setState] = useState(value);

	return (
		<input
			className={inputVariant({ size: "small", className: "-my-1 max-w-[4.5rem]" })}
			type="number"
			value={state}
			onChange={(e) => setState(Number(e.target.value))}
			onBlur={() => onUpdate(state)}
		/>
	);
}
