import {
	faMonkey,
	faPig,
	faShrimp,
	faTeddyBear,
	faUser,
	faUserAlien,
	faUserCowboy,
	faUserHairBuns,
	faUserHairMullet,
	faUserHelmetSafety,
	faUserNinja,
	faUserRobot,
	faUserShakespeare,
	faUserTie,
	faUserVneck,
} from "@fortawesome/pro-solid-svg-icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { sample } from "lodash-es";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { Hello } from "~/components/Hello";
import { Quotes } from "~/components/Quotes";
import { Form, SubmitButton } from "~/components/form/Form";
import { FormInput } from "~/components/form/Input";
import { useZodForm } from "~/hooks/use-form";

export const Route = createFileRoute("/_guest/login")({
	component: RouteComponent,
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const navigate = useNavigate();

	const loginMutation = $api.useMutation("post", "/v1/login");

	const form = useZodForm({
		schema: z.object({ username: z.string(), password: z.string() }),
	});

	const icon = useMemo(() => {
		return sample([
			faUser,
			faUserTie,
			faUserVneck,
			faUserShakespeare,
			faUserRobot,
			faUserNinja,
			faUserCowboy,
			faUserAlien,
			faUserHelmetSafety,
			faUserHairBuns,
			faUserHairMullet,
			faPig,
			faMonkey,
			faShrimp,
			faTeddyBear,
		]);
	}, []);

	return (
		<div className="container flex min-h-dvh xs:max-w-screen-xs items-center justify-center">
			<img
				src="/images/background.jpg"
				alt="background"
				className="fixed inset-0 h-full w-full object-cover blur"
			/>

			<span className="fixed inset-0 bg-primary-9/30" />

			<div className="relative z-1 flex w-full flex-col gap-4 rounded-lg bg-gray-1 p-4 sm:p-8">
				<h1 className="font-bold text-2xl">{t("app_name")}</h1>

				<h2 className="font-bold text-1xl">
					<Hello />
				</h2>

				<Form
					form={form}
					onSubmit={async (values) => {
						await loginMutation.mutateAsync({ body: values });
						await navigate({ to: searchParams.redirect ?? "/" });
					}}
					fieldSetProps={{ className: "flex flex-col gap-4" }}
				>
					<FormInput label={t("username")} autoComplete="username" {...form.register("username")} />
					<FormInput
						label={t("password")}
						autoComplete="current-password"
						{...form.register("password")}
						type="password"
					/>
					<SubmitButton startIcon={icon}>{t("login")}</SubmitButton>
				</Form>

				<hr className="text-gray-6" />

				<Suspense
					fallback={
						<div className="flex flex-col gap-1">
							<span className="h-6 w-full animate-pulse rounded bg-gray-3" />
							<span className="h-5 w-1/4 animate-pulse rounded bg-gray-3" />
						</div>
					}
				>
					<Quotes />
				</Suspense>
			</div>
		</div>
	);
}
