import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faLink, faSidebarFlip } from "@fortawesome/pro-solid-svg-icons";
import { useParams } from "@tanstack/react-router";
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertDialog } from "~/components/dialog/AlertDialog";
import { Icon } from "~/components/utilities/IconVariant";
import { Tooltip } from "~/components/utilities/Tooltip";
import { useClipboard } from "~/hooks/use-clipboard";
import { cn } from "~/hooks/use-tailwind";

export interface ActionButtonsRowProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	variant?: "fixed" | "collapsible";
	items?: ActionButtonProps[];
}

export function ActionButtonsRow({ open, setOpen, variant, items }: ActionButtonsRowProps) {
	const { t } = useTranslation();

	return (
		<div
			className={cn("ml-auto flex justify-end gap-2 self-center", {
				"lg:h-[39px] lg:w-[calc(var(--sidebar-x)-1rem)] lg:items-center lg:border-gray-3 lg:border-l":
					variant === "fixed",
			})}
		>
			<GlobalActions items={items} />

			{variant === "collapsible" && (
				<ActionButton
					id="sidebar"
					tooltip={open ? t("close_sidebar") : t("open_sidebar")}
					active={open}
					onClick={() => setOpen((prev) => !prev)}
					icon={faSidebarFlip}
					className="hidden lg:block"
				/>
			)}
		</div>
	);
}

export function GlobalActions({ items }: { items?: ActionButtonProps[] }) {
	const { t } = useTranslation();
	const clipboard = useClipboard();
	const paramId = useParams({ strict: false, select: (s) => s.id });

	return (
		<>
			{items?.map((item) => (
				<ActionButton key={item.id} {...item} />
			))}

			<ActionButton
				id="copy_url"
				tooltip={t("copy_url")}
				onClick={() => clipboard.mutateAsync(window.location.toString())}
				icon={faLink}
			/>

			{paramId && (
				<ActionButton
					id="copy_id"
					tooltip={t("copy_id")}
					onClick={() => clipboard.mutateAsync(paramId)}
					icon={faCopy}
				/>
			)}
		</>
	);
}

export interface ActionButtonProps {
	id: string;
	tooltip: string;
	isLoading?: boolean;
	active?: boolean;
	icon: IconProp;
	onClick: () => void;
	className?: string;
	withAffirmation?: { title: string; description: string; cancel?: string; confirm?: string };
}

export function ActionButton(props: ActionButtonProps) {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<Tooltip
				content={props.tooltip}
				contentProps={{ className: "text-sm" }}
				triggerProps={{ asChild: true }}
				color="gray"
			>
				<button
					type="button"
					className={cn(
						"block aspect-square w-[25px] shrink-0 rounded border border-gray-3 hover:enabled:bg-gray-3 disabled:cursor-not-allowed disabled:opacity-70",
						{
							"bg-gray-2 text-gray-11": !props.active,
							"bg-gray-3 text-primary-9": props.active,
						},
						props.className,
					)}
					onClick={() => (props.withAffirmation ? setOpen(true) : props.onClick())}
				>
					<Icon icon={props.icon} loading={props.isLoading} size="sm" />
				</button>
			</Tooltip>

			{props.withAffirmation && (
				<AlertDialog
					action={props.withAffirmation.confirm ?? t("yes")}
					actionFn={props.onClick}
					title={props.withAffirmation.title}
					description={props.withAffirmation.description}
					open={open}
					setOpen={setOpen}
				/>
			)}
		</>
	);
}
