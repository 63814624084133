import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api, fetchClient } from "~/api";
import { FastActions } from "~/components/FastActions";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { paginationSchema } from "~/types/route";

const sortOptions = [
	"placed_at",
	"-placed_at",
	"production_date",
	"-production_date",
	"shipping_date",
	"-shipping_date",
	"delivery_date",
	"-delivery_date",
] as const;

const searchSchema = paginationSchema.extend({
	filter: z
		.object({
			increment_id: z.string().optional(),
			placed_at: z.string().optional(),
			production_date: z.string().optional(),
			shipping_date: z.string().optional(),
			delivery_date: z.string().optional(),
		})
		.optional(),
	sort: z.enum(sortOptions).optional(),
});

type SearchParams = z.infer<typeof searchSchema>;

const optimizableOrdersQueryOptions = (params: SearchParams) => {
	return $api.queryOptions("get", "/v1/optimizable-orders", {
		params: {
			query: {
				page: params.page,
				per_page: params.per_page,
				"filter[increment_id]": params.filter?.increment_id,
				"filter[placed_at]": params.filter?.placed_at,
				"filter[production_date]": params.filter?.production_date,
				"filter[shipping_date]": params.filter?.shipping_date,
				"filter[delivery_date]": params.filter?.delivery_date,
				sort: params.sort,
			},
		},
	});
};

export const Route = createFileRoute("/_authenticated/optimizable-orders/")({
	component: RouteComponent,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, deps }) =>
		context.queryClient.ensureQueryData(optimizableOrdersQueryOptions(deps)),
});

function RouteComponent() {
	const { t } = useTranslation();
	const searchParams = Route.useSearch();
	const queryClient = useQueryClient();
	const query = useSuspenseQuery(optimizableOrdersQueryOptions(searchParams));
	const optimizableOrders = query.data;
	const navigate = Route.useNavigate();

	useCustomDocumentTitle(`${t("optimizable_orders")} (${optimizableOrders.meta.total})`);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof optimizableOrders)["data"][number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("store", {
				header: t("store"),
				cell: (info) => (
					<TableCell variant="store" props={info.getValue().value} value={info.getValue().label} />
				),
			}),
			columnHelper.accessor("increment_id", {
				header: `${t("order")} #`,
				cell: (info) => (
					<TableCell
						variant="link"
						value={info.getValue()}
						props={{ to: "/orders/$id", params: { id: info.row.original.id } }}
					/>
				),
			}),
			columnHelper.accessor("placed_at", {
				header: t("placed_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="placed_at" />,
			}),
			columnHelper.accessor("production_date", {
				header: t("production_date"),
				cell: (info) => (
					<TableCell variant="date" value={info.getValue()} props="production_date" />
				),
			}),
			columnHelper.accessor("shipping_date", {
				header: t("shipping_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="shipping_date" />,
			}),
			columnHelper.accessor("delivery_date", {
				header: t("delivery_date"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="delivery_date" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "optimizable-orders",
		pagination: { id: Route.id, rowCount: optimizableOrders.meta.total },
		rowSelection: true,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: optimizableOrders.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	const selected = useMemo(
		() => tableProps.selected.filter((id) => query.data.data.some((item) => item.id === id)),
		[tableProps.selected, query.data.data],
	);

	return (
		<Fragment>
			<TableLayout
				tableProps={{
					table,
					sort: ["placed_at", "production_date", "shipping_date", "delivery_date"],
				}}
				topbarProps={{
					title: t("optimizable_orders"),
					modules: {
						pagination: true,
						rowSelection: true,
						columnVisibility: true,
					},
				}}
				filterItems={[
					{
						variant: "input",
						label: `${t("order")} #`,
						value: "increment_id",
					},
					{
						variant: "date",
						label: t("placed_at"),
						value: "placed_at",
					},
					{
						variant: "date",
						label: t("production_date"),
						value: "production_date",
					},
					{
						variant: "date",
						label: t("shipping_date"),
						value: "shipping_date",
					},
					{
						variant: "date",
						label: t("delivery_date"),
						value: "delivery_date",
					},
				]}
			/>
			<FastActions
				selected={selected}
				clearSelected={() => table.toggleAllRowsSelected(false)}
				actions={[
					{
						id: "create-production-batch",
						label: t("create_production_batch"),
						icon: faCirclePlus,
						action: async () => {
							const res = await fetchClient.POST("/v1/optimizable-orders", {
								body: { order_ids: selected },
							});

							await queryClient.invalidateQueries();

							if (res.data?.id) {
								await navigate({ to: "/production-batches/$id", params: { id: res.data.id } });
							}
						},
					},
				]}
			/>
		</Fragment>
	);
}
