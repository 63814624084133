import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { type Dispatch, type ReactNode, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { Square } from "~/components/utilities/interactive/Square";
import { cn } from "~/hooks/use-tailwind";

type DialogControlProps =
	| {
			open?: never;
			setOpen?: never;
			trigger?: DialogPrimitive.DialogTriggerProps;
	  }
	| {
			open: boolean;
			setOpen: Dispatch<SetStateAction<boolean>>;
			trigger?: never;
	  };

interface DefaultDialogProps {
	title: string;
	description?: string;
	children: ReactNode;
	className?: string;
	forceOpen?: boolean;
	width?: "xs" | "sm";
}

export type DialogProps = DialogControlProps & DefaultDialogProps;

export function Dialog(props: DialogProps) {
	const { title, description, children, className, forceOpen, width = "sm", trigger } = props;
	const { t } = useTranslation();
	const [openState, setOpenState] = useState(false);

	const open = props.open ?? openState;
	const setOpen = forceOpen ? undefined : (props.setOpen ?? setOpenState);

	return (
		<DialogPrimitive.Root open={open} onOpenChange={setOpen}>
			{trigger && <DialogPrimitive.Trigger {...trigger} />}

			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className="fixed inset-0 z-3 rdx-state-closed:animate-opacity-out rdx-state-open:animate-opacity-in bg-app-background/50 backdrop-blur-[2px]" />
				<DialogPrimitive.Content className="!pointer-events-none fixed bottom-1/2 z-3 flex w-full translate-y-1/2 rdx-state-closed:animate-opacity-scale-out rdx-state-open:animate-opacity-scale-in items-center justify-center">
					<div
						className={cn(
							"background-floating-box pointer-events-auto mx-2 w-full p-4 focus:outline-none sm:max-w-screen-sm md:mx-6",
							{ "sm:max-w-screen-sm": width === "sm", "xs:max-w-screen-xs": width === "xs" },
						)}
					>
						{(title || description || !forceOpen) && (
							<div className="mb-2 flex min-h-8 items-start justify-between">
								<div>
									{title && (
										<DialogPrimitive.Title asChild>
											<Typography weight="bold" size="lg" className="leading-tight">
												{title}
											</Typography>
										</DialogPrimitive.Title>
									)}

									{description && (
										<DialogPrimitive.Description asChild>
											<Typography color="gray" contrast="low">
												{description}
											</Typography>
										</DialogPrimitive.Description>
									)}
								</div>

								{!forceOpen && (
									<DialogPrimitive.Close asChild>
										<Square size="small">
											<button
												type="button"
												onClick={() => setOpen?.(false)}
												title={t("close_dialog")}
												className="flex items-center justify-center"
											>
												<Icon icon={faTimes} size="xl" />
											</button>
										</Square>
									</DialogPrimitive.Close>
								)}
							</div>
						)}

						<div className={className}>{children}</div>
					</div>
				</DialogPrimitive.Content>
			</DialogPrimitive.Portal>
		</DialogPrimitive.Root>
	);
}
