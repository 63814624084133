import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createRouter, useMatches } from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HTTPError } from "~/api/middleware/error-middleware";
import { NotFoundPageComponent } from "~/components/route/NotFound";
import "../css/app.css";
import "./i18n";
import { routeTree } from "./routeTree.gen";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (import.meta.env.DEV) {
					return false;
				}

				const codes = [408, 429, 419, 500, 502, 503, 504];

				// always retry queries
				return error instanceof HTTPError && codes.includes(error.statusCode)
					? failureCount <= 3
					: false;
			},
		},
		mutations: {
			retry: (failureCount, error) => {
				if (import.meta.env.DEV) {
					return false;
				}

				// only retry mutations if the error is a csrf mismatch
				return error instanceof HTTPError && error.statusCode === 419 ? failureCount < 1 : false;
			},
		},
	},
});

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration()],
	tracesSampleRate: 0.1,
});

// Create a new router instance
const router = createRouter({
	routeTree,
	context: {
		queryClient,
	},
	defaultPreload: "intent",

	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
	defaultNotFoundComponent: (props) => {
		const matches = useMatches();
		const isAuth = matches.some((match) => match.id === "/_authenticated");

		return <NotFoundPageComponent fullHeight={!isAuth} {...props} />;
	},
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

// Render the app
const rootElement = document.getElementById("root");

if (rootElement && !rootElement?.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);

	root.render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<RouterProvider router={router} />
			</QueryClientProvider>
		</StrictMode>,
	);
}
