import { faPen, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import type { paths } from "~/api/openapi";
import { Dialog } from "~/components/dialog/Default";
import { Form, SubmitButton } from "~/components/form/Form";
import { FormInput } from "~/components/form/Input";
import { Button } from "~/components/utilities/interactive/Button";
import { TableCell } from "~/components/utilities/table/Cell";
import { useToast } from "~/components/utilities/toast/use-toast";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useZodForm } from "~/hooks/use-form";
import { useTableProps } from "~/hooks/use-table";
import { PageSidebarLayout } from "~/layouts/PageSidebarLayout";
import { TableLayout } from "~/layouts/TableLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";
import { formatter } from "~/library/utilities";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "~/types/route";

const productionBatchQueryOptions = (id: string) => {
	return $api.queryOptions("get", "/v1/production-batches/{productionBatch}", {
		params: { path: { productionBatch: id } },
	});
};

type SuccessResponse =
	paths["/v1/production-batches/{productionBatch}"]["get"]["responses"][200]["content"]["application/json"];

export const Route = createFileRoute("/_authenticated/production-batches/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(productionBatchQueryOptions(params.id)),
});

function RouteComponent() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { toast } = useToast();
	const params = Route.useParams();
	const productionBatchQuery = useSuspenseQuery({
		...productionBatchQueryOptions(params.id),
		refetchInterval: ({ state }) => {
			const optimizations = [
				...(state.data?.optimizations ?? []),
				...(state.data?.pre_optimizations ?? []),
			];
			const isOptimizing = optimizations.some((o) => o.status.value === "optimizing");

			// when there are pending optimizations, enable refetching
			return isOptimizing ? 2000 : false;
		},
	});
	const productionBatch = productionBatchQuery.data;

	const title = [
		formatter({
			variant: "date",
			value: new Date(productionBatch.production_date),
			lang: i18n.language,
		}),
		productionBatch.increment_id,
		productionBatch.comment,
	]
		.filter(Boolean)
		.join(" / ");

	const deleteBatch = $api.useMutation("delete", "/v1/production-batches/{productionBatch}", {
		onSuccess: async () => {
			toast({ title: t("production_batch_deleted") });

			await navigate({
				to: "/production-batches",
				search: { page: DEFAULT_PAGE_INDEX, per_page: DEFAULT_PAGE_SIZE },
			});
		},
	});

	useCustomDocumentTitle(`${t("production_batch")} | ${title}`);

	return (
		<PageSidebarLayout
			title={title}
			backLink={{ to: "/production-batches", children: t("production_batches") }}
			actions={[
				{
					id: "delete",
					icon: faTrash,
					tooltip: t("delete_production_batch"),
					onClick: () => {
						return deleteBatch.mutateAsync({
							params: { path: { productionBatch: productionBatch.id } },
						});
					},
					withAffirmation: {
						title: t("are_you_sure_you_want_to_delete_this_production_batch"),
						description: t("this_action_cannot_be_undone"),
						cancel: t("no_dont_delete"),
						confirm: t("yes_delete"),
					},
				},
			]}
			sidebar={
				<>
					<SideBarBlock title={t("information")}>
						<SidebarItem label={t("comment")} value={productionBatch.comment} />
						<SidebarItem label={t("increment_id")} value={productionBatch.increment_id} />
						<SidebarDateItem variant="production_date" value={productionBatch.production_date} />
						<SidebarItem label={t("amount_of_sheets")} value={productionBatch.optimizations_sum} />
					</SideBarBlock>

					<SideBarBlock title={t("additional_information")}>
						<SidebarDateItem variant="created_at" value={productionBatch.created_at} />
						<SidebarDateItem variant="updated_at" value={productionBatch.updated_at} />
					</SideBarBlock>
				</>
			}
		>
			<OptimizationsLayout data={productionBatch.optimizations} />
			<PreOptimizationsLayout data={productionBatch.pre_optimizations} />
		</PageSidebarLayout>
	);
}

type Optimization = NonNullable<SuccessResponse["optimizations"]>[number];

function OptimizationsLayout(props: { data: SuccessResponse["optimizations"] }) {
	const { data } = props;
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<Optimization>();

		return [
			columnHelper.display({
				id: "edit",
				enableHiding: false,
				cell: (info) => {
					const formSchema = z.object({
						length: z.number(),
						width: z.number(),
						trim_length: z.number(),
						trim_width: z.number(),
						quantity: z.number(),
					});

					const form = useZodForm({
						schema: formSchema,
						defaultValues: {
							length: info.row.original.length,
							width: info.row.original.width,
							trim_length: info.row.original.trim_length,
							trim_width: info.row.original.trim_width,
							quantity: info.row.original.quantity,
						},
					});

					const [open, setOpen] = useState(false);

					const updateOptimizations = $api.useMutation("put", "/v1/optimizations/{optimization}", {
						onSuccess: async () => {
							await queryClient.invalidateQueries();
							setOpen(false);
						},
					});

					return (
						<>
							<Button
								startIcon={faPen}
								variant="action"
								size="small"
								onClick={() => setOpen((prev) => !prev)}
							/>

							<Dialog
								open={open}
								setOpen={setOpen}
								title={`${t("edit_optimization")} #${info.row.original.id}`}
							>
								<Form
									form={form}
									onSubmit={(values) =>
										updateOptimizations.mutateAsync({
											params: { path: { optimization: info.row.original.id } },
											body: values,
										})
									}
									fieldSetProps={{ className: "flex flex-col gap-4" }}
								>
									<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:last:odd:*:col-span-full">
										<FormInput
											type="number"
											label={t("length")}
											{...form.register("length", { valueAsNumber: true })}
											suffix="mm"
										/>
										<FormInput
											type="number"
											label={t("width")}
											{...form.register("width", { valueAsNumber: true })}
											suffix="mm"
										/>
										<FormInput
											type="number"
											label={t("trim_length")}
											{...form.register("trim_length", { valueAsNumber: true })}
											suffix="mm"
										/>
										<FormInput
											type="number"
											label={t("trim_width")}
											{...form.register("trim_width", { valueAsNumber: true })}
											suffix="mm"
										/>
										<FormInput
											type="number"
											label={t("quantity")}
											{...form.register("quantity", { valueAsNumber: true })}
										/>
									</div>

									<SubmitButton>{t("save_and_restart_optimization")}</SubmitButton>
								</Form>
							</Dialog>
						</>
					);
				},
			}),
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("type", {
				header: t("type"),
				cell: (info) => <TableCell variant="default" value={info.getValue().label} />,
			}),
			columnHelper.accessor("producible.material", {
				header: t("material"),
				cell: (info) => {
					const material = info.getValue();

					if (!material) {
						return null;
					}

					return (
						<TableCell
							variant="link"
							props={{
								to: "/materials/$id",
								params: { id: material.id },
							}}
							value={material.name}
						/>
					);
				},
			}),
			columnHelper.accessor("length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("trim_length", {
				header: t("trim_length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("trim_width", {
				header: t("trim_width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("quantity", {
				header: t("quantity"),
				cell: (info) => <TableCell variant="default" value={info.getValue().toString()} />,
			}),
			columnHelper.accessor("status", {
				header: t("status"),
				cell: (info) => (
					<TableCell
						variant="status"
						props={{ variant: "optimization", current: info.getValue().value }}
						value={info.getValue().label}
					/>
				),
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t, queryClient.invalidateQueries]);

	const tableProps = useTableProps({
		id: "production-batch-optimizations",
		pagination: false,
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: data ?? [],
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			isSubElement
			tableProps={{ table, fallback: t("no_optimizations_found") }}
			topbarProps={{
				title: t("optimizations"),
				modules: { pagination: false, rowSelection: false, columnVisibility: true },
			}}
		/>
	);
}

type PreOptimization = NonNullable<SuccessResponse["pre_optimizations"]>[number];

function PreOptimizationsLayout(props: { data: SuccessResponse["pre_optimizations"] }) {
	const { data } = props;
	const { t } = useTranslation();

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<PreOptimization>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("material", {
				header: t("material"),
				cell: (info) => {
					const material = info.getValue();

					if (!material) {
						return null;
					}

					return (
						<TableCell
							variant="link"
							props={{
								to: "/materials/$id",
								params: { id: material.id },
							}}
							value={material.name}
						/>
					);
				},
			}),
			columnHelper.accessor("stock_position", {
				header: t("stock_position"),
				cell: (info) => <TableCell variant="default" value={info.getValue().label} />,
			}),
			columnHelper.accessor("use_rest_sheets", {
				header: t("use_rest_sheets"),
				cell: (info) => <TableCell variant="boolean" value={!!info.getValue()} />,
			}),
			columnHelper.accessor("status", {
				header: t("status"),
				cell: (info) => (
					<TableCell
						variant="status"
						props={{ variant: "optimization", current: info.getValue().value }}
						value={info.getValue().label}
					/>
				),
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "production-batch-pre-optimizations",
		pagination: false,
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: data ?? [],
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			isSubElement
			tableProps={{ table, fallback: t("no_pre_optimizations_found") }}
			topbarProps={{
				title: t("pre_optimizations"),
				modules: { pagination: false, rowSelection: false, columnVisibility: true },
			}}
		/>
	);
}
