import { useIsFetching } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useMediaQuery } from "usehooks-ts";
import { NavigationContent } from "~/components/utilities/layout/navigation/Content";
import { cn } from "~/hooks/use-tailwind";

export function Navigation() {
	const { t } = useTranslation();
	const isMin1024 = useMediaQuery("(min-width: 1024px)");
	const [expanded, setExpanded] = useLocalStorage("hemlock-navigation-expanded", isMin1024);
	const expandedState = isMin1024 ? expanded : false;
	const isFetching = useIsFetching();

	return (
		<div
			className={cn(
				"sticky top-0 z-2 xs:flex hidden h-full max-h-dvh shrink-0 flex-col gap-6 py-4 pl-4 motion-safe:transition-all",
				{ "w-[calc(196px+1rem)]": expandedState, "w-[56px]": !expandedState },
			)}
		>
			{isMin1024 ? (
				<button
					type="button"
					onClick={() => setExpanded((prev) => !prev)}
					title={expandedState ? t("collapse_navigation") : t("expand_navigation")}
					className="h-12 w-12 rounded-md outline-none focus-visible:ring-1 focus-visible:ring-primary-7"
				>
					<img
						src="/images/logo-icon.png"
						className={cn({ "animate-pulse": !!isFetching })}
						alt={t("app_name")}
					/>
				</button>
			) : (
				<img
					src="/images/logo-icon.png"
					alt={t("app_name")}
					className={cn("h-12 w-12", {
						"animate-pulse": !!isFetching,
					})}
				/>
			)}

			<NavigationContent expanded={expandedState} toggleable />
		</div>
	);
}
