import * as DialogPrimitive from "@radix-ui/react-alert-dialog";
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "~/components/utilities/Typography";
import { Button } from "~/components/utilities/interactive/Button";

type DialogControlProps =
	| {
			open?: never;
			setOpen?: never;
			trigger?: DialogPrimitive.AlertDialogTriggerProps;
	  }
	| {
			open: boolean;
			setOpen: Dispatch<SetStateAction<boolean>>;
			trigger?: never;
	  };
interface DefaultDialogProps {
	title: string;
	description?: string;
	className?: string;
	action: string;
	actionFn: () => void | Promise<void>;
}

type DialogProps = DialogControlProps & DefaultDialogProps;
export function AlertDialog(props: DialogProps) {
	const { title, description, trigger, action, actionFn } = props;
	const [openState, setOpenState] = useState(false);

	const open = props.open ?? openState;
	const setOpen = props.setOpen ?? setOpenState;

	const { t } = useTranslation();

	return (
		<DialogPrimitive.Root open={open} onOpenChange={setOpen}>
			{trigger && <DialogPrimitive.Trigger {...trigger} />}

			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className="fixed inset-0 z-3 rdx-state-closed:animate-opacity-out rdx-state-open:animate-opacity-in bg-app-background/50 backdrop-blur-[2px]" />

				<DialogPrimitive.Content className="!pointer-events-none fixed bottom-1/2 z-3 flex w-full translate-y-1/2 rdx-state-closed:animate-opacity-scale-out rdx-state-open:animate-opacity-scale-in items-center justify-center">
					<div className="background-floating-box pointer-events-auto mx-2 w-full p-4 focus:outline-none sm:max-w-screen-xs md:mx-6">
						<div className="flex flex-col gap-2">
							{title && (
								<DialogPrimitive.Title asChild>
									<Typography weight="bold" size="lg">
										{title}
									</Typography>
								</DialogPrimitive.Title>
							)}

							{description && (
								<DialogPrimitive.Description asChild>
									<Typography color="gray" contrast="low">
										{description}
									</Typography>
								</DialogPrimitive.Description>
							)}
						</div>

						<div className="mt-8 flex justify-end gap-8">
							<DialogPrimitive.Cancel asChild>
								<Button color="gray" variant="link">
									{t("cancel")}
								</Button>
							</DialogPrimitive.Cancel>

							<DialogPrimitive.Action asChild>
								<Button
									color="red"
									onClick={async () => {
										await actionFn();

										setOpen(false);
									}}
								>
									{action}
								</Button>
							</DialogPrimitive.Action>
						</div>
					</div>
				</DialogPrimitive.Content>
			</DialogPrimitive.Portal>
		</DialogPrimitive.Root>
	);
}
