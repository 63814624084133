import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { z } from "zod";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import type { components, paths } from "~/api/openapi";
import { Tooltip } from "~/components/utilities/Tooltip";
import { TableCell } from "~/components/utilities/table/Cell";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { PageSidebarLayout } from "~/layouts/PageSidebarLayout";
import { TableLayout } from "~/layouts/TableLayout";
import { SideBarBlock, SidebarItem } from "~/layouts/utilities/Sidebar";
import { formatter } from "~/library/utilities";
import { paginationSchema } from "~/types/route";

const searchSchema = paginationSchema;
type SearchParams = z.infer<typeof searchSchema>;

const materialRestSheetsQueryOptions = (id: string, params: SearchParams) =>
	$api.queryOptions("get", "/v1/materials/{material}/rest-sheets", {
		params: { path: { material: id }, query: params },
	});

const materialQueryOptions = (id: string) =>
	$api.queryOptions("get", "/v1/materials/{material}", {
		params: { path: { material: id } },
	});

type SuccessResponse =
	paths["/v1/materials/{material}"]["get"]["responses"][200]["content"]["application/json"];

export const Route = createFileRoute("/_authenticated/materials/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	validateSearch: (search) => searchSchema.parse(search),
	loaderDeps: ({ search }) => search,
	loader: ({ context, params, deps }) => {
		const materialQuery = context.queryClient.ensureQueryData(materialQueryOptions(params.id));
		const restSheetsQuery = context.queryClient.ensureQueryData(
			materialRestSheetsQueryOptions(params.id, deps),
		);

		return Promise.all([materialQuery, restSheetsQuery]);
	},
});

function RouteComponent() {
	const { t, i18n } = useTranslation();
	const params = Route.useParams();
	const materialQuery = useSuspenseQuery(materialQueryOptions(params.id));

	useCustomDocumentTitle(materialQuery.data.name);

	return (
		<PageSidebarLayout
			title={materialQuery.data.name}
			backLink={{ to: "/materials", children: t("materials") }}
			sidebar={
				<div className="flex flex-col gap-4">
					<SideBarBlock title={t("information")}>
						{[
							{ label: t("sku"), value: materialQuery.data.sku },
							{ label: t("name"), value: materialQuery.data.name },
							{
								label: t("thickness"),
								value: formatter({
									lang: i18n.language,
									value: materialQuery.data.thickness,
									variant: "millimeter",
								}),
							},
							{ label: t("grain"), value: materialQuery.data.grain.label },
						].map((item) => (
							<SidebarItem key={item.label} label={item.label} value={item.value} />
						))}
					</SideBarBlock>

					{materialQuery.data.image && (
						<Tooltip
							contentProps={{ className: "py-2", side: "left" }}
							content={
								<img
									src={materialQuery.data.image_detail}
									alt={materialQuery.data.name}
									width={1920}
									height={960}
								/>
							}
						>
							<img
								src={materialQuery.data.image}
								alt={materialQuery.data.name}
								width={1920}
								height={960}
							/>
						</Tooltip>
					)}
				</div>
			}
		>
			<SheetsLayout data={materialQuery.data.sheets} />
			<RestSheetsLayout />
		</PageSidebarLayout>
	);
}

function SheetsLayout(props: { data: SuccessResponse["sheets"] }) {
	const { data } = props;
	const { t } = useTranslation();

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<components["schemas"]["SheetResource"]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("cost.formatted", {
				header: t("cost"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("total_cost.formatted", {
				header: t("total_cost"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("weight", {
				header: t("weight"),
				cell: (info) => <TableCell variant="kilogram" value={info.getValue() ?? 0} />,
			}),
			columnHelper.accessor("is_default", {
				header: t("is_default"),
				cell: (info) => <TableCell variant="boolean" value={info.getValue()} />,
			}),
			columnHelper.accessor("is_active", {
				header: t("is_active"),
				cell: (info) => <TableCell variant="boolean" value={info.getValue()} />,
			}),
			columnHelper.accessor("is_per_square_meter", {
				header: t("is_per_square_meter"),
				cell: (info) => <TableCell variant="boolean" value={info.getValue()} />,
			}),
			columnHelper.accessor("stock_position.label", {
				header: t("stock_position"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("supplier.label", {
				header: t("supplier"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("supplier_sku", {
				header: t("supplier_sku"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("supplier_name", {
				header: t("supplier_product_name"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "material-sheets",
		pagination: false,
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: data ?? [],
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			isSubElement
			tableProps={{ table }}
			topbarProps={{
				title: t("sheets"),
				modules: { pagination: false, rowSelection: false, columnVisibility: true },
			}}
		/>
	);
}

type RestSheetType = NonNullable<SuccessResponse["rest_sheets"]>[number];

function RestSheetsLayout() {
	const { t } = useTranslation();
	const params = Route.useParams();
	const searchParams = Route.useSearch();
	const query = useSuspenseQuery(materialRestSheetsQueryOptions(params.id, searchParams));
	const restSheets = query.data;

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<RestSheetType>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("barcode", {
				header: t("barcode"),
				cell: (info) => (
					<TableCell
						variant="link"
						value={info.getValue()}
						props={{ to: "/rest-sheets/$id", params: { id: info.row.original.id } }}
					/>
				),
			}),
			columnHelper.accessor("rack_code", {
				header: t("rack_code"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("reserved_at", {
				header: t("reserved_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="reserved_at" />,
			}),
			columnHelper.accessor("used_at", {
				header: t("used_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="used_at" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "material-rest-sheets",
		pagination: { id: Route.id, rowCount: restSheets.meta.total },
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: restSheets.data,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	return (
		<TableLayout
			isSubElement
			tableProps={{ table, rowLink: { to: "/rest-sheets/$id" } }}
			topbarProps={{
				title: t("rest_sheets"),
				modules: { pagination: true, rowSelection: false, columnVisibility: true },
			}}
		/>
	);
}
